@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .animate-float {
    animation: float 2s ease-out forwards;
  }

  @keyframes float {
    0% {
      transform: translateY(0) translateX(-50%);
      opacity: 1;
    }
    30% {
      transform: translateY(-50px) translateX(-45%);
    }
    60% {
      transform: translateY(-150px) translateX(-40%);
    }
    100% {
      transform: translateY(-200px) translateX(-35%);
      opacity: 0;
    }
  }
}

html {
  font-family: poppins;
}

.img-button {
  background: #3e2e70;

  border-radius: 12px;
  padding: 0;
  cursor: pointer;
  outline-offset: 4px;
}

.img-button img {
  display: block;

  border-radius: 12px;
  transform: translateY(-6px);

  -webkit-animation: mover 0.5s cubic-bezier(0.01, 0.88, 0.36, 1) 1s infinite
    alternate;

  animation: mover 0.5s cubic-bezier(0.01, 0.88, 0.36, 1) 1s infinite alternate;
}

@-webkit-keyframes mover {
  0% {
    transform: translateY(-2px);
  }
  50% {
    transform: translateY(-6px);
  }

  100% {
    transform: translateY(-2px);
  }
}
@keyframes mover {
  0% {
    transform: translateY(-2px);
  }
  50% {
    transform: translateY(-6px);
  }

  100% {
    transform: translateY(-2px);
  }
}

.story-content p {
  margin-bottom: 20px;
}

.ant-tour {
  max-width: 100%;
}
