body {
  margin: 0;
  padding: 0;
}
.react-calendar__tile--active {
  background: #006edc !important;
  color: white !important;
}

div[data-vimeo-initialized="true"] {
  border-radius: 6px;
}
